
/**
* if set to device-width ie10 built-in scrollbar overflows content. Fix it as of here
* http://stackoverflow.com/questions/17045132/scrollbar-overlay-in-ie10-how-do-you-stop-that-might-be-bootstrap
*/
@-ms-viewport{
  width: auto;
}

html, body{
  height: 100%;
}

html {
  font-size: $font-size-initial;
}

body{
  font-weight: $font-weight-base;
  overflow-x: hidden;
}

th{
  font-weight: $font-weight-semi-bold;
}

b, strong {
  font-weight: $font-weight-semi-bold;
}